import DashboardCarousel from './components/DashboardCarousel'
import './App.css';

function App() {
  return (
    <DashboardCarousel/>
  );
}

export default App;
