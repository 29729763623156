import Tile from '../Tile'

function Dashboard(props) {
  return (
    <div>
      <h2>{props.config.title}</h2>
      {props.config.rows.map(function(row_elements) {
        return (
          <div class="flex-grid">
            {row_elements.map(function(tile_info) {
              return (
                <div class="col">
                  <Tile config={tile_info} />
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  );
}

export default Dashboard;
