import React, { useEffect, useState } from 'react'
import Dashboard from '../Dashboard'

function DashboardCarousel() {
  const carousel_config = {
    "dashboards": [
      {
        "title": "Disco APIs",
        "rows": [
          [
            {
              iframe_url: "https://chart-embed.service.newrelic.com/herald/54ad61c7-82bd-4124-8968-a02d4c104bc1?height=300px&timepicker=true"
            },
            {
              iframe_url: "https://chart-embed.service.newrelic.com/herald/4af5ff33-a528-4220-8fdd-823acbc6ed33?height=300px&timepicker=true"
            },
            {
              iframe_url: "https://chart-embed.service.newrelic.com/herald/1e9af5cb-c78c-499e-952d-916ef7349517?height=300px&timepicker=true"
            }
          ],
          [
            {
              iframe_url: "https://chart-embed.service.newrelic.com/herald/95c805dd-9e28-43b1-b20a-31eeab146d1a?height=300px&timepicker=true"
            },
            {
              iframe_url: "https://chart-embed.service.newrelic.com/herald/95c805dd-9e28-43b1-b20a-31eeab146d1a?height=300px&timepicker=true"
            },
            {
              iframe_url: "https://chart-embed.service.newrelic.com/herald/a4a3334e-f12f-4a8e-b8a5-7001b0fe4a89?height=300px&timepicker=true"
            }
          ]
        ]
      },

      {
        "title": "Living -- Booking APIs",
        "rows": [
          [
            {
              iframe_url: "https://chart-embed.service.newrelic.com/herald/a11b472d-5659-46f1-b914-8439109bc1aa?height=300px&timepicker=true"
            },
            {
              iframe_url: "https://chart-embed.service.newrelic.com/herald/c2ed3fc3-5185-481a-b8ed-c71877e03199?height=300px&timepicker=true"
            },
            {
              iframe_url: "https://chart-embed.service.newrelic.com/herald/c6f89699-bf4a-4c95-9c9e-7d3574f23a94?height=300px&timepicker=true"
            }
          ],
          [
            {
              iframe_url: "https://chart-embed.service.newrelic.com/herald/a77275c8-2a5d-467b-838f-970c64cd3e7b?height=300px&timepicker=true"
            },
            {
              iframe_url: ""
            },
            {
              iframe_url: ""
            }
          ]
        ]
      },
      {
        "title": "Host APIs",
        "rows": [
          [
            {
              iframe_url: "https://chart-embed.service.newrelic.com/herald/2d21a07b-048f-41a4-bb5e-3c93453dd5dc?height=300px&timepicker=true"
            },
            {
              iframe_url: "https://chart-embed.service.newrelic.com/herald/da4b58b4-d324-4d44-8e45-d71449170910?height=300px&timepicker=true"
            },
            {
              iframe_url: "https://chart-embed.service.newrelic.com/herald/5a17f88b-41b0-4f0a-bab2-2c2c003f3605?height=300px&timepicker=true"
            }
          ]
        ]
      }
    ]
  }

  const [activeIndex, setActiveIndex] = useState(0);

  // make the slideshow advance!!
  useEffect(() => {
    const interval = setInterval(() => {
      var nextIndex = activeIndex == carousel_config.dashboards.length - 1 ? 0 : activeIndex + 1
      console.log("next index: " + nextIndex)
      setActiveIndex(nextIndex);
    }, 20000);

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <div>
      <h2>Deez Charts!</h2>
      {carousel_config.dashboards.map(function(dashboard_config, index) {
        const dashboard_class = index == activeIndex ? "dashboard active-dashboard" : "dashboard"
        return (
          <div class={dashboard_class}>
            <Dashboard config={dashboard_config}/>
          </div>
        )
      })}
    </div>
  );
}

export default DashboardCarousel;
